/* global localStorage */
export const THEME_KEY = 'theme'

export const setValue = (key, value) => localStorage.setItem(key, value)

export const getValue = key => localStorage.getItem(key)

export const loadTheme = () => {
  return getValue(THEME_KEY)
}

export const saveTheme = ({ theme }) => {
  setValue(THEME_KEY, theme)
  return theme
}
