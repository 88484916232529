import {
  createSelector,
} from 'reselect'

export const getSessionState = (state) => state.session

export const getIsLoggedIn = createSelector(
  [
    getSessionState,
  ], (
    sessionState,
  ) => sessionState.isLoggedIn,
)

export const getUser = createSelector(
  [
    getSessionState,
  ], (
    sessionState,
  ) => sessionState.user,
)

export const getUserEmail = createSelector(
  [
    getUser,
  ], (
    user,
  ) => user?.username,
)

export const getProfile = createSelector(
  [
    getSessionState,
  ], (
    sessionState,
  ) => sessionState.profile,
)

export const getUsername = createSelector(
  [
    getProfile,
    getUserEmail,
  ], (
    profile,
    userEmail,
  ) => profile?.username || userEmail,
)
