import {
  PLAY_AUDIO_REQUEST,
  PLAY_AUDIO_SUCCESS,
  PLAY_AUDIO_FAILURE,
  STOP_AUDIO_SUCCESS,
} from 'constants/audio'

export const getDefaultState = () => ({
  isSoundPlaying: false,
})

const audioReducer = (state = getDefaultState(), action) => {
  if (action.type === PLAY_AUDIO_REQUEST) {
    return ({
      ...state,
      isSoundPlaying: true,
    })
  }
  if (
    action.type === PLAY_AUDIO_SUCCESS ||
    action.type === PLAY_AUDIO_FAILURE ||
    action.type === STOP_AUDIO_SUCCESS
  ) {
    return ({
      ...state,
      isSoundPlaying: false,
    })
  }
  return state
}

export default audioReducer
