export const LOADING_INDICATOR_HIDE_DELAY = 500

export const MODAL_OPEN = 'ui/modal/open'
export const MODAL_CLOSE = 'ui/modal/close'
export const DRAWER_OPEN = 'ui/drawer/open'
export const DRAWER_CLOSE = 'ui/drawer/close'
export const SNACKBAR_ENQUEUE = 'ui/snackbar/enqueue'
export const SNACKBAR_CLOSE = 'ui/snackbar/close'
export const SNACKBAR_REMOVE = 'ui/snackbar/remove'
export const STORE_PARAMS = 'ui/params/store'
export const SAVE_THEME_REQUEST = 'ui/theme/save/request'
export const SAVE_THEME_SUCCESS = 'ui/theme/save/success'
export const SAVE_THEME_FAILURE = 'ui/theme/save/failure'
export const LOAD_THEME_REQUEST = 'ui/theme/load/request'
export const LOAD_THEME_SUCCESS = 'ui/theme/load/success'
export const LOAD_THEME_FAILURE = 'ui/theme/load/failure'
export const UPLOAD_FILE_TO_LIBRARY = 'ui/uploadFileToLibrary'
export const UPLOAD_FILE_REQUEST = 'ui/uploadFile/request'
export const UPLOAD_FILE_SUCCESS = 'ui/uploadFile/success'
export const UPLOAD_FILE_FAILURE = 'ui/uploadFile/failure'
export const LIST_FILES_REQUEST = 'ui/listFiles/request'
export const LIST_FILES_SUCCESS = 'ui/listFiles/success'
export const LIST_FILES_FAILURE = 'ui/listFiles/failure'

export const THEME_LIGHT = 'light'
export const THEME_DARK = 'dark'
