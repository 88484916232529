import React from 'react'
import {
  connect,
} from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import SoundboxProjectCreateForm from 'forms/SoundboxProjectCreate'
import {
  closeModal as closeModalAction,
} from 'actions/ui'
import {
  soundboxProjectCreate as soundboxProjectCreateSubmission,
} from 'submissions/soundbox'
import {
  soundboxProjectCreate as soundboxProjectCreateAction,
} from 'actions/soundbox'
import {
  loadAuthToken as loadAuthTokenAction,
} from 'actions/session'

export const SoundboxProjectCreate = ({
  loadAuthToken,
  closeModal,
  soundboxProjectCreate,
}) => {
  return (
    <React.Fragment>
      <DialogTitle>Add a project</DialogTitle>
      <DialogContent>
        <SoundboxProjectCreateForm
            initialValues={{
            }}
            onSubmit={soundboxProjectCreateSubmission(
              loadAuthToken,
              soundboxProjectCreate,
            )}
        />
      </DialogContent>
      <DialogActions>
        <Button
            onClick={closeModal}
            color="primary"
          >
          Close modal
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export const mapStateToProps = (state, ownProps) => ({
})

export const mapDispatchToProps = {
  closeModal: closeModalAction,
  soundboxProjectCreate: soundboxProjectCreateAction,
  loadAuthToken: loadAuthTokenAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(SoundboxProjectCreate)
