import {
  storeParams,
  listFiles,
} from 'actions/ui'
import {
  currentUserInfo,
} from 'actions/session'

export const ensureStoreParams = store => async (nextState, replace, next) => {
  store.dispatch(storeParams(nextState.params))
  next()
}

export const ensureListFiles = store => async (nextState, replace, next) => {
  const {
    response: {
      attributes: {
        sub: subject,
      },
    },
  } = await store.dispatch(currentUserInfo())
  await store.dispatch(listFiles(`${subject}/`, 'public'))
  next()
}
