;[
  'REGION',
  'IDENTITY_POOL_ID',
  'USER_POOL_ID',
  'USER_POOL_WEB_CLIENT_ID',
].forEach((environmentVariable) => {
  if (!process.env[environmentVariable]) {
    throw new Error(`config/cognito: environment variable '${environmentVariable}' is required`)
  }
})

export default ({
  region: process.env.REGION,
  identityPoolId: process.env.IDENTITY_POOL_ID,
  userPoolId: process.env.USER_POOL_ID,
  userPoolWebClientId: process.env.USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
})
