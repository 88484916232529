import {
  select,
  putResolve,
  call,
  takeEvery,
  put,
  delay,
} from 'redux-saga/effects'

import {
  SOUNDBOX_ADD_TRACK_FAILURE,
  SOUNDBOX_ADD_TRACK_SUCCESS,
  CREATE_SOUNDBOX_PROJECT_SUCCESS,
  LOAD_SOUNDBOX_PROJECTS_FAILURE,
  CREATE_SOUNDBOX_PROJECT_FAILURE,
  LOAD_SOUNDBOX_PROJECT_FAILURE,
} from 'constants/soundbox'
import {
  enqueueSnackbar,
  closeModal,
} from 'actions/ui'
import {
  SubmissionError,
  reset as formReset,
} from 'redux-form'
import {
  loadAuthToken,
} from 'actions/session'
import {
  loadSoundboxProjects,
  loadSoundboxProject,
} from 'actions/soundbox'
import {
  getProjectId,
} from 'selectors/soundbox'

export function * loadSoundboxProjectsFailureEffect ({
  error,
}) {
  yield delay(0)
  yield put(enqueueSnackbar({
    message: error,
    options: {
      key: `load-sandbox-projects-failure-${Math.random().toString(16).slice(-6)}`,
      variant: 'error',
    },
  }))
}

export function * loadSoundboxProjectsFailure () {
  yield takeEvery(
    LOAD_SOUNDBOX_PROJECTS_FAILURE,
    loadSoundboxProjectsFailureEffect,
  )
}

export function * createSoundboxProjectFailureEffect ({
  error,
  form,
}) {
  yield call(form.reject, new SubmissionError({
    _error: error,
  }))
}

export function * createSoundboxProjectFailure () {
  yield takeEvery(
    CREATE_SOUNDBOX_PROJECT_FAILURE,
    createSoundboxProjectFailureEffect,
  )
}

export function * createSoundboxProjectSuccessEffect ({
  form,
}) {
  yield put(closeModal())
  yield call(form.resolve)
  yield put(formReset('soundbox-project-create'))
  const {
    response: token,
  } = yield putResolve(loadAuthToken())
  yield put(loadSoundboxProjects(token))
}

export function * createSoundboxProjectSuccess () {
  yield takeEvery(
    CREATE_SOUNDBOX_PROJECT_SUCCESS,
    createSoundboxProjectSuccessEffect,
  )
}

export function * loadSoundboxProjectFailureEffect ({
  error,
}) {
  yield delay(0)
  yield put(enqueueSnackbar({
    message: error,
    options: {
      key: `load-sandbox-project-failure-${Math.random().toString(16).slice(-6)}`,
      variant: 'error',
    },
  }))
}

export function * loadSoundboxProjectFailure () {
  yield takeEvery(
    LOAD_SOUNDBOX_PROJECT_FAILURE,
    loadSoundboxProjectFailureEffect,
  )
}

export function * soundboxAddTrackFailureEffect ({
  error,
  form,
}) {
  yield call(form.reject, new SubmissionError({
    _error: error,
  }))
}

export function * soundboxAddTrackFailure () {
  yield takeEvery(
    SOUNDBOX_ADD_TRACK_FAILURE,
    soundboxAddTrackFailureEffect,
  )
}

export function * soundboxAddTrackSuccessEffect ({
  form,
}) {
  yield put(closeModal())
  yield call(form.resolve)
  yield put(formReset('soundbox-project-add-track'))
  const {
    response: token,
  } = yield putResolve(loadAuthToken())
  const soundboxProjectId = yield select(getProjectId)
  yield put(loadSoundboxProject(
    token,
    soundboxProjectId,
  ))
}

export function * soundboxAddTrackSuccess () {
  yield takeEvery(
    SOUNDBOX_ADD_TRACK_SUCCESS,
    soundboxAddTrackSuccessEffect,
  )
}
