export const name = (values) => {
  const errors = {}
  if (!values?.name) {
    errors.name = 'Required'
  }
  return errors
}

export const uri = (values) => {
  const errors = {}
  if (!values?.uri) {
    errors.uri = 'Required'
  }
  return errors
}

export const soundboxProjectCreate = (values) => ({
  ...name(values),
})

export const soundboxAddTrack = (values) => ({
  ...uri(values),
})
