import {
  putResolve,
  takeEvery,
  put,
  delay,
} from 'redux-saga/effects'

import {
  UPLOAD_FILE_TO_LIBRARY,
  UPLOAD_FILE_FAILURE,
} from 'constants/ui'
import {
  enqueueSnackbar,
  uploadFile,
  listFiles,
} from 'actions/ui'
import {
  currentUserInfo,
} from 'actions/session'

export function * uploadFileFailureEffect ({
  error,
}) {
  yield delay(0)
  yield put(enqueueSnackbar({
    message: error,
    options: {
      key: `upload-file-failure-${Math.random().toString(16).slice(-6)}`,
      variant: 'error',
    },
  }))
}

export function * uploadFileFailure () {
  yield takeEvery(
    UPLOAD_FILE_FAILURE,
    uploadFileFailureEffect,
  )
}

export function * uploadFileToLibraryEffect ({
  files,
}) {
  const {
    response: {
      attributes: {
        sub: subject,
      },
    },
  } = yield putResolve(currentUserInfo())
  yield putResolve(uploadFile(
    files[0],
    'public',
    `${subject}/`,
  ))
  yield put(listFiles(`${subject}`, 'public'))
}

export function * uploadFileToLibrary () {
  yield takeEvery(
    UPLOAD_FILE_TO_LIBRARY,
    uploadFileToLibraryEffect,
  )
}
