import {
  UPLOAD_FILE_TO_LIBRARY,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  LIST_FILES_REQUEST,
  LIST_FILES_SUCCESS,
  LIST_FILES_FAILURE,
  MODAL_OPEN,
  MODAL_CLOSE,
  DRAWER_OPEN,
  DRAWER_CLOSE,
  SNACKBAR_ENQUEUE,
  SNACKBAR_CLOSE,
  SNACKBAR_REMOVE,
  STORE_PARAMS,
  SAVE_THEME_REQUEST,
  SAVE_THEME_SUCCESS,
  SAVE_THEME_FAILURE,
  LOAD_THEME_REQUEST,
  LOAD_THEME_SUCCESS,
  LOAD_THEME_FAILURE,
} from 'constants/ui'
import {
  CALL_LOCAL_STORAGE,
} from 'middlewares/localStorage'
import {
  CALL_S3,
} from 'middlewares/s3'

export const uploadFileToLibrary = (
  files,
) => ({
  type: UPLOAD_FILE_TO_LIBRARY,
  files,
})

export const listFiles = (
  path,
  level = 'public',
  resolve,
  reject,
) => ({
  [CALL_S3]: {
    method: 'list',
    data: {
      level,
      path,
    },
    types: [
      LIST_FILES_REQUEST,
      LIST_FILES_SUCCESS,
      LIST_FILES_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const uploadFile = (
  file,
  level,
  path,
  resolve,
  reject,
) => ({
  [CALL_S3]: {
    method: 'uploadFile',
    data: {
      file,
      level,
      path,
    },
    types: [
      UPLOAD_FILE_REQUEST,
      UPLOAD_FILE_SUCCESS,
      UPLOAD_FILE_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const openModal = (
  name,
  options = {}
) => ({
  type: MODAL_OPEN,
  name,
  options,
})

export const closeModal = () => ({
  type: MODAL_CLOSE,
})

export const openDrawer = () => ({
  type: DRAWER_OPEN,
})

export const closeDrawer = () => ({
  type: DRAWER_CLOSE,
})

export const enqueueSnackbar = (
  notification,
) => {
  const key = notification?.options?.key || new Date().getTime() + Math.random()
  return ({
    type: SNACKBAR_ENQUEUE,
    notification: {
      ...notification,
      key: key,
    },
  })
}

export const closeSnackbar = (
  key,
) => ({
  type: SNACKBAR_CLOSE,
  dismissAll: !key,
  key,
})

export const removeSnackbar = (
  key,
) => ({
  type: SNACKBAR_REMOVE,
  key,
})

export const storeParams = (
  params,
) => ({
  type: STORE_PARAMS,
  params,
})

export const saveTheme = (
  theme,
) => ({
  [CALL_LOCAL_STORAGE]: {
    method: 'saveTheme',
    data: {
      theme,
    },
    types: [
      SAVE_THEME_REQUEST,
      SAVE_THEME_SUCCESS,
      SAVE_THEME_FAILURE,
    ],
  },
})

export const loadTheme = () => ({
  [CALL_LOCAL_STORAGE]: {
    method: 'loadTheme',
    types: [
      LOAD_THEME_REQUEST,
      LOAD_THEME_SUCCESS,
      LOAD_THEME_FAILURE,
    ],
  },
})
