import React from 'react'
import {
  makeStyles,
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  connect,
} from 'react-redux'

export const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
}))

export const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Typography
          variant="body2"
          color="textSecondary"
          align="center"
        >
        Open Owl
      </Typography>
    </footer>
  )
}

export const mapStateToProps = () => ({
})

export const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
