import * as cognitoService from 'services/cognito'

export const CALL_COGNITO = Symbol('CALL_COGNITO')
export const COGNITO_EVENT = 'cognito/event'

export async function makeCognitoCall (
  method,
  data = {},
) {
  try {
    const result = await cognitoService[method](data)
    return result
  } catch (e) {
    console.log('Error caught in `middlewares/cognito` -- ', e.message, { method, data })
    throw e
  }
}

export function actionWith (
  action,
  dat,
  form,
  extra,
  data,
) {
  const finalAction = {
    ...action,
    ...dat,
    form,
    extra,
    data,
  }
  delete action[CALL_COGNITO]
  return finalAction
}

export default (store) => {
  cognitoService.addListener((payload) => {
    store.dispatch({
      type: COGNITO_EVENT,
      payload,
    })
  })
  return (next) => async (action) => {
    const callCognito = action[CALL_COGNITO]
    if (typeof callCognito === 'undefined') {
      return next(action)
    }
    const {
      method,
      types,
      data,
      form,
      extra,
    } = callCognito
    const [
      REQUEST,
      SUCCESS,
      FAILURE,
    ] = types
    next(actionWith(action, { type: REQUEST }, form, extra, data))
    return makeCognitoCall(
      method,
      data,
    ).then(
      (response) => next(actionWith(action, {
        response,
        type: SUCCESS,
      }, form, extra, data)),
      (e) => next(actionWith(action, {
        type: FAILURE,
        error: e.message || e.error,
      }, form, extra, data)),
    )
  }
}
