export const PLAY_AUDIO_REQUEST = 'audio/play/request'
export const PLAY_AUDIO_SUCCESS = 'audio/play/success'
export const PLAY_AUDIO_FAILURE = 'audio/play/failure'
export const STOP_AUDIO_REQUEST = 'audio/stop/request'
export const STOP_AUDIO_SUCCESS = 'audio/stop/success'
export const STOP_AUDIO_FAILURE = 'audio/stop/failure'
export const LOOP_AUDIO_REQUEST = 'audio/loop/request'
export const LOOP_AUDIO_SUCCESS = 'audio/loop/success'
export const LOOP_AUDIO_FAILURE = 'audio/loop/failure'
export const SET_AUDIO_VOLUME_REQUEST = 'audio/setVolume/request'
export const SET_AUDIO_VOLUME_SUCCESS = 'audio/setVolume/success'
export const SET_AUDIO_VOLUME_FAILURE = 'audio/setVolume/failure'
export const AUDIO_END = 'audio/end'
export const AUDIO_UNLOAD_REQUEST = 'audio/unload/request'
export const AUDIO_UNLOAD_SUCCESS = 'audio/unload/success'
export const AUDIO_UNLOAD_FAILURE = 'audio/unload/failure'
