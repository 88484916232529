;[
  'REGION',
  'USER_CONTENT_BUCKET',
].forEach((environmentVariable) => {
  if (!process.env[environmentVariable]) {
    throw new Error(`config/s3: environment variable '${environmentVariable}' is required`)
  }
})

export default ({
  region: process.env.REGION,
  bucket: process.env.USER_CONTENT_BUCKET,
})
