import React from 'react'
import {
  reduxForm,
  FormSection,
} from 'redux-form'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import TrackField from 'fields/Track'
import {
  validateFields,
} from 'utils/form'
import {
  soundboxAddTrack as soundboxAddTrackValidations,
} from 'validations/soundbox'

export const SoundboxAddTrack = ({
  tracks,
  handleSubmit,
  submitting,
  error,
}) => (
  <form onSubmit={handleSubmit}>
    {error && (
      <Typography color="error">{error}</Typography>
    )}
    <FormSection name="track">
      <Grid container>
        <Grid
            item
            xs={12}
          >
          <TrackField
              {...{
                tracks,
              }}
          />
        </Grid>
        <Grid
            item
            xs={12}
          >
          <Button
              variant="contained"
              type="submit"
              disabled={submitting}
              fullWidth
              color="primary"
            >
            Add
          </Button>
        </Grid>
      </Grid>
    </FormSection>
  </form>
)

export default reduxForm({
  form: 'soundbox-project-add-track',
  validate: validateFields({
    track: soundboxAddTrackValidations,
  }),
})(SoundboxAddTrack)
