import {
  Howl,
} from 'howler'

import {
  AUDIO_END,
} from 'constants/audio'

const activeChannels = ({})
const loopLookup = ({})

let dispatch

export const init = (
  dispatchFn,
) => {
  dispatch = dispatchFn
}

export const unload = () => new Promise((resolve, reject) => {
  const channelKeys = Object.keys(activeChannels)
  for (let i = 0; i < channelKeys.length; i++) {
    const channel = channelKeys[i]
    activeChannels[channel].sound.unload()
    delete activeChannels[channel]
    delete loopLookup[channel]
  }
  resolve()
})

export const play = ({
  channel,
  src,
}) => new Promise((resolve, reject) => {
  try {
    if (activeChannels[channel] === undefined) {
      activeChannels[channel] = ({
        sound: new Howl({
          src,
          onend: () => {
            dispatch({
              type: AUDIO_END,
              channel: channel,
            })
          },
        }),
        volume: 1,
      })
    }
    const sound = activeChannels[channel].sound
    sound.volume(activeChannels[channel].volume)
    sound.play()
    sound.loop(loopLookup[channel])
    sound.once('end', () => {
      resolve(null)
    })
  } catch (e) {
    reject(e)
  }
})

export const stop = ({
  channel,
}) => new Promise((resolve, reject) => {
  try {
    activeChannels[channel].sound.pause()
    activeChannels[channel].sound.seek(0)
    resolve(null)
  } catch (e) {
    reject(e)
  }
})

export const loop = ({
  channel,
  isActive,
}) => new Promise((resolve, reject) => {
  try {
    loopLookup[channel] = isActive
    if (activeChannels[channel] !== undefined) {
      activeChannels[channel].sound.loop(isActive)
    }
    resolve(null)
  } catch (e) {
    reject(e)
  }
})

export const volume = ({
  channel,
  value,
}) => new Promise((resolve, reject) => {
  try {
    activeChannels[channel].sound.volume(value)
    activeChannels[channel].volume = value
    resolve(null)
  } catch (e) {
    reject(e)
  }
})
