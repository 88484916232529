import React from 'react'
import {
  Field,
} from 'redux-form'

import SelectControl from 'controls/Select'

const Track = ({
  tracks,
}) => {
  const options = tracks.map(({ key, uri }) => ({
    label: key.split('/').slice(-1)[0],
    value: uri,
  }))
  return (
    <Field
        label="Track"
        name="uri"
        component={SelectControl}
        {...{
          options,
        }}
    />
  )
}

export default Track
