export const SIGN_IN_REQUEST = 'session/signIn/request'
export const SIGN_IN_SUCCESS = 'session/signIn/success'
export const SIGN_IN_FAILURE = 'session/signIn/failure'
export const SIGN_OUT_REQUEST = 'session/signOut/request'
export const SIGN_OUT_SUCCESS = 'session/signOut/success'
export const SIGN_OUT_FAILURE = 'session/signOut/failure'
export const CURRENT_SESSION_REQUEST = 'session/currentSession/request'
export const CURRENT_SESSION_SUCCESS = 'session/currentSession/success'
export const CURRENT_SESSION_FAILURE = 'session/currentSession/failure'
export const CURRENT_USER_INFO_REQUEST = 'session/currentUserInfo/request'
export const CURRENT_USER_INFO_SUCCESS = 'session/currentUserInfo/success'
export const CURRENT_USER_INFO_FAILURE = 'session/currentUserInfo/failure'
export const LOAD_AUTH_TOKEN_REQUEST = 'session/loadAuthToken/request'
export const LOAD_AUTH_TOKEN_SUCCESS = 'session/loadAuthToken/success'
export const LOAD_AUTH_TOKEN_FAILURE = 'session/loadAuthToken/failure'
export const SIGN_UP_REQUEST = 'session/signUp/request'
export const SIGN_UP_SUCCESS = 'session/signUp/success'
export const SIGN_UP_FAILURE = 'session/signUp/failure'
export const SIGN_UP_CONFIRM_REQUEST = 'session/signUpConfirm/request'
export const SIGN_UP_CONFIRM_SUCCESS = 'session/signUpConfirm/success'
export const SIGN_UP_CONFIRM_FAILURE = 'session/signUpConfirm/failure'
export const LOAD_PROFILE_REQUEST = 'session/loadProfile/request'
export const LOAD_PROFILE_SUCCESS = 'session/loadProfile/success'
export const LOAD_PROFILE_FAILURE = 'session/loadProfile/failure'
export const SAVE_PROFILE_REQUEST = 'session/saveProfile/request'
export const SAVE_PROFILE_SUCCESS = 'session/saveProfile/success'
export const SAVE_PROFILE_FAILURE = 'session/saveProfile/failure'
export const FORGOT_PASSWORD_REQUEST = 'password/forgot/request'
export const FORGOT_PASSWORD_SUCCESS = 'password/forgot/success'
export const FORGOT_PASSWORD_FAILURE = 'password/forgot/failure'
export const FORGOT_PASSWORD_SUBMIT_REQUEST = 'password/forgotSubmit/request'
export const FORGOT_PASSWORD_SUBMIT_SUCCESS = 'password/forgotSubmit/success'
export const FORGOT_PASSWORD_SUBMIT_FAILURE = 'password/forgotSubmit/failure'
