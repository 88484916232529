import React, {
  Suspense,
} from 'react'
import {
  withStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  connect,
} from 'react-redux'

import Modal from 'containers/ModalBase'
import darkTheme from 'styles/darkTheme'
import LoadingIndicator from 'components/LoadingIndicator'
import {
  LOADING_INDICATOR_HIDE_DELAY,
  THEME_DARK,
} from 'constants/ui'
import {
  getIsModalOpen,
  getTheme,
} from 'selectors/ui'
import {
  getInProgress,
} from 'selectors/loading'
import {
  loadTheme as loadThemeAction,
  saveTheme as saveThemeAction,
} from 'actions/ui'

const styles = () => ({
  root: {
    display: 'flex',
  },
})

export class Session extends React.Component {
  constructor (props) {
    super(props)
    this.loadingTimer = null
  }

  UNSAFE_componentWillMount () { // eslint-disable-line camelcase
    this.props.loadTheme().then((result) => {
      this.props.saveTheme(result.response)
    })
  }

  state = {
    isLoading: false,
  }

  UNSAFE_componentWillReceiveProps ( // eslint-disable-line camelcase
    newProps,
  ) {
    if (newProps.inProgress && this.state.isLoading) {
      clearInterval(this.loadingTimer)
    } else if (newProps.inProgress && !this.state.isLoading) {
      clearInterval(this.loadingTimer)
      this.setState({
        isLoading: true,
      })
    } else if (!newProps.inProgress && this.state.isLoading) {
      clearInterval(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        this.setState({
          isLoading: false
        })
      }, LOADING_INDICATOR_HIDE_DELAY)
    }
  }

  render () {
    const theme = createTheme({
      palette: {
        ...(this.props.theme === THEME_DARK && darkTheme),
      },
    })
    return (
      <ThemeProvider {...{ theme }}>
        <div className={this.props.classes.root}>
          <CssBaseline />
          <Suspense fallback={(<LoadingIndicator />)}>
            {this.props.children}
          </Suspense>
          {this.state.isLoading && (
            <LoadingIndicator />
          )}
          {this.props.isModalOpen && (
            <Modal />
          )}
        </div>
      </ThemeProvider>
    )
  }
}

export const mapStateToProps = (
  state,
  ownProps,
) => ({
  inProgress: getInProgress(state, ownProps),
  isModalOpen: getIsModalOpen(state, ownProps),
  theme: getTheme(state, ownProps),
})

export const mapDispatchToProps = ({
  loadTheme: loadThemeAction,
  saveTheme: saveThemeAction,
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Session))
