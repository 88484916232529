export const validateFields = (
  validators,
  requiredFields = {},
) => {
  return values => {
    const validationErrors = Object.keys(validators).map(name => ({
      name,
      error: validators[name](values[name]),
    })).reduce((p, { name, error }) => (
      Object.keys(name).length ? { ...p, [name]: error } : p
    ), {})
    Object.keys(requiredFields).forEach(fieldName => {
      Object.assign(validationErrors[fieldName], requiredFields[fieldName](values[fieldName]))
    })
    return validationErrors
  }
}
