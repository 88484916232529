import React from 'react'
import {
  Field,
} from 'redux-form'

import TextControl from 'controls/Text'

const Name = () => {
  return (
    <Field
        label="Name"
        name="name"
        component={TextControl}
    />
  )
}

export default Name
