import {
  SIGN_UP_CONFIRM_REQUEST,
  SIGN_UP_CONFIRM_SUCCESS,
  SIGN_UP_CONFIRM_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  CURRENT_SESSION_REQUEST,
  CURRENT_SESSION_SUCCESS,
  CURRENT_SESSION_FAILURE,
  CURRENT_USER_INFO_REQUEST,
  CURRENT_USER_INFO_SUCCESS,
  CURRENT_USER_INFO_FAILURE,
  LOAD_PROFILE_REQUEST,
  LOAD_PROFILE_SUCCESS,
  LOAD_PROFILE_FAILURE,
  SAVE_PROFILE_REQUEST,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILURE,
  FORGOT_PASSWORD_SUBMIT_REQUEST,
  FORGOT_PASSWORD_SUBMIT_SUCCESS,
  FORGOT_PASSWORD_SUBMIT_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from 'constants/session'
import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  LIST_FILES_REQUEST,
  LIST_FILES_SUCCESS,
  LIST_FILES_FAILURE,
} from 'constants/ui'
import {
  LOAD_SOUNDBOX_PROJECTS_REQUEST,
  LOAD_SOUNDBOX_PROJECTS_SUCCESS,
  LOAD_SOUNDBOX_PROJECTS_FAILURE,
  CREATE_SOUNDBOX_PROJECT_REQUEST,
  CREATE_SOUNDBOX_PROJECT_SUCCESS,
  CREATE_SOUNDBOX_PROJECT_FAILURE,
  LOAD_SOUNDBOX_PROJECT_REQUEST,
  LOAD_SOUNDBOX_PROJECT_SUCCESS,
  LOAD_SOUNDBOX_PROJECT_FAILURE,
  SOUNDBOX_ADD_TRACK_REQUEST,
  SOUNDBOX_ADD_TRACK_SUCCESS,
  SOUNDBOX_ADD_TRACK_FAILURE,
} from 'constants/soundbox'

export const loadingTypes = ({
  soundboxAddTrack: {
    [SOUNDBOX_ADD_TRACK_REQUEST]: true,
    [SOUNDBOX_ADD_TRACK_SUCCESS]: false,
    [SOUNDBOX_ADD_TRACK_FAILURE]: false,
  },
  loadSoundboxProject: {
    [LOAD_SOUNDBOX_PROJECT_REQUEST]: true,
    [LOAD_SOUNDBOX_PROJECT_SUCCESS]: false,
    [LOAD_SOUNDBOX_PROJECT_FAILURE]: false,
  },
  loadSoundboxProjects: {
    [LOAD_SOUNDBOX_PROJECTS_REQUEST]: true,
    [LOAD_SOUNDBOX_PROJECTS_SUCCESS]: false,
    [LOAD_SOUNDBOX_PROJECTS_FAILURE]: false,
  },
  createSoundboxProject: {
    [CREATE_SOUNDBOX_PROJECT_REQUEST]: true,
    [CREATE_SOUNDBOX_PROJECT_SUCCESS]: false,
    [CREATE_SOUNDBOX_PROJECT_FAILURE]: false,
  },
  forgotPassword: {
    [FORGOT_PASSWORD_REQUEST]: true,
    [FORGOT_PASSWORD_SUCCESS]: false,
    [FORGOT_PASSWORD_FAILURE]: false,
  },
  forgotPasswordSubmit: {
    [FORGOT_PASSWORD_SUBMIT_REQUEST]: true,
    [FORGOT_PASSWORD_SUBMIT_SUCCESS]: false,
    [FORGOT_PASSWORD_SUBMIT_FAILURE]: false,
  },
  currentSession: {
    [CURRENT_SESSION_REQUEST]: true,
    [CURRENT_SESSION_SUCCESS]: false,
    [CURRENT_SESSION_FAILURE]: false,
  },
  currentUserInfo: {
    [CURRENT_USER_INFO_REQUEST]: true,
    [CURRENT_USER_INFO_SUCCESS]: false,
    [CURRENT_USER_INFO_FAILURE]: false,
  },
  signOut: {
    [SIGN_OUT_REQUEST]: true,
    [SIGN_OUT_SUCCESS]: false,
    [SIGN_OUT_FAILURE]: false,
  },
  signUpConfirm: {
    [SIGN_UP_CONFIRM_REQUEST]: true,
    [SIGN_UP_CONFIRM_SUCCESS]: false,
    [SIGN_UP_CONFIRM_FAILURE]: false,
  },
  signUp: {
    [SIGN_UP_REQUEST]: true,
    [SIGN_UP_SUCCESS]: false,
    [SIGN_UP_FAILURE]: false,
  },
  signIn: {
    [SIGN_IN_REQUEST]: true,
    [SIGN_IN_SUCCESS]: false,
    [SIGN_IN_FAILURE]: false,
  },
  saveProfile: {
    [SAVE_PROFILE_REQUEST]: true,
    [SAVE_PROFILE_SUCCESS]: false,
    [SAVE_PROFILE_FAILURE]: false,
  },
  loadProfile: {
    [LOAD_PROFILE_REQUEST]: true,
    [LOAD_PROFILE_SUCCESS]: false,
    [LOAD_PROFILE_FAILURE]: false,
  },
  uploadFile: {
    [UPLOAD_FILE_REQUEST]: true,
    [UPLOAD_FILE_SUCCESS]: false,
    [UPLOAD_FILE_FAILURE]: false,
  },
  listFiles: {
    [LIST_FILES_REQUEST]: true,
    [LIST_FILES_SUCCESS]: false,
    [LIST_FILES_FAILURE]: false,
  },
})

export const getDefaultState = () => Object.keys(loadingTypes).reduce((p, c) => ({
  ...p,
  [c]: false,
}), {})

export default (
  state = getDefaultState(),
  action,
) => {
  const loadingTypesKeys = Object.keys(loadingTypes)
  for (let index = 0; index < loadingTypesKeys.length; index++) {
    const loadingPropName = loadingTypesKeys[index]
    const loadingType = loadingTypes[loadingPropName]
    if (loadingType[action.type] !== undefined) {
      return {
        ...state,
        [loadingPropName]: loadingType[action.type],
      }
    }
  }
  return state
}
