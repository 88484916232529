import React from 'react'
import {
  createSelector,
} from 'reselect'
import {
  Link as RouterLink,
} from 'react-router'
import Link from '@material-ui/core/Link'

import {
  getParams,
} from 'selectors/ui'
import {
  SOUNDBOX_PROJECT_PAGE,
} from 'constants/navigation'

export const getSoundboxState = (state) => state.soundbox

export const getSoundboxProjects = createSelector(
  [
    getSoundboxState,
  ], (
    soundboxState,
  ) => soundboxState.projects,
)

export const getSoundboxProjectsListRows = createSelector(
  [
    getSoundboxProjects,
  ], (
    soundboxProjects,
  ) => soundboxProjects.map((soundboxProject) => [{
    key: 'name',
    value: soundboxProject.name,
    content: (
      <Link
          to={`/${SOUNDBOX_PROJECT_PAGE}/${soundboxProject.id}`}
          variant="body2"
          component={RouterLink}
        >
        {soundboxProject.name}
      </Link>
    ),
  }])
)

export const getSoundboxProject = createSelector(
  [
    getSoundboxState,
  ], (
    soundboxState,
  ) => soundboxState.project,
)

export const getProjectId = createSelector(
  [
    getParams,
  ], (
    params,
  ) => params.soundboxProjectId,
)

export const getSoundboxProjectTracks = createSelector(
  [
    getSoundboxProject,
  ], (
    soundboxProject,
  ) => soundboxProject.tracks,
)

export const getSoundboxAudioStates = createSelector(
  [
    getSoundboxState,
  ], (
    soundboxState,
  ) => soundboxState.audioStates,
)
