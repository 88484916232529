import React from 'react'
import {
  connect,
} from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'

import {
  getModalOptions,
} from 'selectors/ui'
import {
  closeModal as closeModalAction,
} from 'actions/ui'

export const Sandbox = ({
  closeModal,
  options,
}) => {
  const [
    currentIndex,
    setCurrentIndex,
  ] = React.useState(options.imageIndex)
  return (
    <React.Fragment>
      <DialogTitle>Image</DialogTitle>
      <DialogContent>
        <img
            src={options.images[currentIndex]}
            style={{
              width: '100%',
            }}
        />
      </DialogContent>
      <DialogActions>
        <ButtonGroup
            color="primary"
            variant="outlined"
          >
          <Button
              onClick={() => setCurrentIndex(currentIndex - 1)}
              disabled={currentIndex <= 0}
            >
            Previous
          </Button>
          <Button
              onClick={() => setCurrentIndex(currentIndex + 1)}
              disabled={currentIndex >= options.images.length - 1}
            >
            Next
          </Button>
        </ButtonGroup>
        <Button
            onClick={closeModal}
            color="primary"
          >
          Close modal
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export const mapStateToProps = (
  state,
  ownProps,
) => ({
  options: getModalOptions(state, ownProps),
})

export const mapDispatchToProps = {
  closeModal: closeModalAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sandbox)
