/* global fetch */

export const CALL_FETCH = Symbol('CALL_FETCH')

export const makeFetchCall = async (
  endpoint,
  data = {},
  method = 'GET',
  headers = {},
) => {
  try {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      method,
    }
    if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      options.body = JSON.stringify(data)
    }
    const response = await fetch(
      endpoint,
      options,
    )
    let json
    try {
      json = await response.json()
    } catch (e) {
      if (response.status === 404) {
        throw new Error(`Endpoint '${endpoint}' not found`)
      }
      throw e
    }
    if (!response.ok) {
      throw new Error(json.message)
    }
    return json
  } catch (e) {
    console.log('Error caught in `middlewares/fetch` -- ', e.message, { method })
    throw e
  }
}

export const actionWith = (
  action,
  dat,
  form,
  extra,
) => {
  const finalAction = {
    ...action,
    ...dat,
    form,
    extra,
  }
  delete finalAction[CALL_FETCH]
  return finalAction
}

export default (store) => (next) => async (action) => {
  const callFetch = action[CALL_FETCH]
  if (typeof callFetch === 'undefined') {
    return next(action)
  }
  const {
    method,
    types,
    data,
    form,
    extra,
    headers,
    endpoint,
  } = callFetch
  const [
    REQUEST,
    SUCCESS,
    FAILURE,
  ] = types
  next(actionWith(action, { type: REQUEST }, form, extra))
  return makeFetchCall(
    endpoint,
    data,
    method,
    headers,
  ).then(
    (response) => next(actionWith(action, {
      response,
      type: SUCCESS,
    }, form, extra)),
    (e) => next(actionWith(action, {
      type: FAILURE,
      error: e.message || e.error,
    }, form, extra))
  )
}
