import {
  loadAuthToken,
  signIn,
  currentSession,
  currentUserInfo,
  loadProfile,
} from 'actions/session'
import {
  LOGIN_PAGE,
  PROFILE_PAGE,
} from 'constants/navigation'
import {
  getIsLoggedIn,
} from 'selectors/session'
import {
  LOAD_PROFILE_FAILURE,
} from 'constants/session'

export const ensureSignIn = store => async (nextState, replace, next) => {
  await store.dispatch(signIn(
    'username',
    'P4@ssword',
  ))
  next()
}

export const ensureCurrentSession = store => async (nextState, replace, next) => {
  await store.dispatch(currentSession())
  next()
}

export const ensureCurrentUserInfo = store => async (nextState, replace, next) => {
  await store.dispatch(currentUserInfo())
  next()
}

export const ensureLoggedIn = store => async (nextState, replace, next) => {
  const state = store.getState()
  const isLoggedIn = getIsLoggedIn(
    state,
    nextState,
  )
  if (!isLoggedIn) {
    replace({
      pathname: `/${LOGIN_PAGE}`,
      state: {
        nextPathname: nextState.location.pathname,
      },
    })
  }
  next()
}

export const ensureProfileComplete = store => async (nextState, replace, next) => {
  const {
    response: token,
  } = await store.dispatch(loadAuthToken())
  const {
    type,
  } = await store.dispatch(loadProfile(token))
  if (type === LOAD_PROFILE_FAILURE) {
    replace(`/${PROFILE_PAGE}`)
  }
  next()
}
