import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux'
import {
  routerMiddleware,
} from 'react-router-redux'
import {
  browserHistory,
} from 'react-router'
import createSagaMiddleware from 'redux-saga'

import reducer from 'reducers'
import localStorageMiddleware from 'middlewares/localStorage'
import fetchMiddleware from 'middlewares/fetch'
import sagas from 'sagas'
import cognitoMiddleware from 'middlewares/cognito'
import s3Middleware from 'middlewares/s3'
import audioMiddleware from 'middlewares/audio'

const sagaMiddleware = createSagaMiddleware()
const router = routerMiddleware(browserHistory)
const enhancer = compose(applyMiddleware(
  cognitoMiddleware,
  fetchMiddleware,
  localStorageMiddleware,
  s3Middleware,
  audioMiddleware,
  router,
  sagaMiddleware, // the order of these matter; probably leave saga as last, so it can respond to fetch/cognito/etc
))

const configureStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    enhancer,
  )
  sagaMiddleware.run(sagas)
  return store
}

export default configureStore
