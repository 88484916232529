import {
  CALL_FETCH,
} from 'middlewares/fetch'
import {
  CALL_COGNITO,
} from 'middlewares/cognito'
import {
  FORGOT_PASSWORD_SUBMIT_REQUEST,
  FORGOT_PASSWORD_SUBMIT_SUCCESS,
  FORGOT_PASSWORD_SUBMIT_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  CURRENT_SESSION_REQUEST,
  CURRENT_SESSION_SUCCESS,
  CURRENT_SESSION_FAILURE,
  CURRENT_USER_INFO_REQUEST,
  CURRENT_USER_INFO_SUCCESS,
  CURRENT_USER_INFO_FAILURE,
  LOAD_AUTH_TOKEN_REQUEST,
  LOAD_AUTH_TOKEN_SUCCESS,
  LOAD_AUTH_TOKEN_FAILURE,
  SIGN_UP_CONFIRM_REQUEST,
  SIGN_UP_CONFIRM_SUCCESS,
  SIGN_UP_CONFIRM_FAILURE,
  LOAD_PROFILE_REQUEST,
  LOAD_PROFILE_SUCCESS,
  LOAD_PROFILE_FAILURE,
  SAVE_PROFILE_REQUEST,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILURE,
} from 'constants/session'

export const forgotPassword = (
  email,
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'forgotPassword',
    data: {
      email,
    },
    types: [
      FORGOT_PASSWORD_REQUEST,
      FORGOT_PASSWORD_SUCCESS,
      FORGOT_PASSWORD_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const forgotPasswordSubmit = (
  email,
  code,
  password,
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'forgotPasswordSubmit',
    data: {
      email,
      code,
      password,
    },
    types: [
      FORGOT_PASSWORD_SUBMIT_REQUEST,
      FORGOT_PASSWORD_SUBMIT_SUCCESS,
      FORGOT_PASSWORD_SUBMIT_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const saveProfile = (
  token,
  username,
  resolve,
  reject,
) => ({
  [CALL_FETCH]: {
    endpoint: '/api/profile',
    method: 'POST',
    data: {
      username,
    },
    headers: {
      Authorization: token,
    },
    types: [
      SAVE_PROFILE_REQUEST,
      SAVE_PROFILE_SUCCESS,
      SAVE_PROFILE_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const loadProfile = (
  token,
  resolve,
  reject,
) => ({
  [CALL_FETCH]: {
    endpoint: '/api/profile',
    method: 'GET',
    headers: {
      Authorization: token,
    },
    types: [
      LOAD_PROFILE_REQUEST,
      LOAD_PROFILE_SUCCESS,
      LOAD_PROFILE_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const signUpConfirm = (
  email,
  code,
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'confirmSignUp',
    data: {
      email,
      code,
    },
    types: [
      SIGN_UP_CONFIRM_REQUEST,
      SIGN_UP_CONFIRM_SUCCESS,
      SIGN_UP_CONFIRM_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const loadAuthToken = (
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'loadAuthToken',
    types: [
      LOAD_AUTH_TOKEN_REQUEST,
      LOAD_AUTH_TOKEN_SUCCESS,
      LOAD_AUTH_TOKEN_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const currentSession = (
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'currentSession',
    types: [
      CURRENT_SESSION_REQUEST,
      CURRENT_SESSION_SUCCESS,
      CURRENT_SESSION_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const currentUserInfo = (
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'currentUserInfo',
    types: [
      CURRENT_USER_INFO_REQUEST,
      CURRENT_USER_INFO_SUCCESS,
      CURRENT_USER_INFO_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const signUp = (
  email,
  password,
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'signUp',
    data: {
      email,
      username: email,
      password,
    },
    types: [
      SIGN_UP_REQUEST,
      SIGN_UP_SUCCESS,
      SIGN_UP_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const signIn = (
  username,
  password,
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'signIn',
    data: {
      username,
      password,
    },
    types: [
      SIGN_IN_REQUEST,
      SIGN_IN_SUCCESS,
      SIGN_IN_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const logout = (
  resolve,
  reject,
) => ({
  [CALL_COGNITO]: {
    method: 'signOut',
    types: [
      SIGN_OUT_REQUEST,
      SIGN_OUT_SUCCESS,
      SIGN_OUT_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})
