import {
  LOAD_SOUNDBOX_PROJECTS_SUCCESS,
  LOAD_SOUNDBOX_PROJECT_SUCCESS,
} from 'constants/soundbox'
import {
  PLAY_AUDIO_REQUEST,
  LOOP_AUDIO_REQUEST,
  STOP_AUDIO_REQUEST,
  AUDIO_END,
} from 'constants/audio'

export const getDefaultState = () => ({
  projects: [],
  project: null,
  audioStates: {},
})

const soundboxReducer = (state = getDefaultState(), action) => {
  if (action.type === STOP_AUDIO_REQUEST) {
    return ({
      ...state,
      audioStates: {
        ...state.audioStates,
        [action.data.channel]: {
          ...state.audioStates[action.data.channel],
          isPlaying: false,
        },
      },
    })
  }
  if (action.type === AUDIO_END) {
    return ({
      ...state,
      audioStates: {
        ...state.audioStates,
        [action.channel]: {
          ...state.audioStates[action.channel],
          isPlaying: state.audioStates[action.channel].isLooping,
        },
      },
    })
  }
  if (action.type === PLAY_AUDIO_REQUEST) {
    return ({
      ...state,
      audioStates: {
        ...state.audioStates,
        [action.data.channel]: {
          ...state.audioStates[action.data.channel],
          isPlaying: true,
        },
      },
    })
  }
  if (action.type === LOOP_AUDIO_REQUEST) {
    return ({
      ...state,
      audioStates: {
        ...state.audioStates,
        [action.data.channel]: {
          ...state.audioStates[action.data.channel],
          isLooping: action.data.isActive,
        },
      },
    })
  }
  if (action.type === LOAD_SOUNDBOX_PROJECT_SUCCESS) {
    const {
      response: {
        project,
      },
    } = action
    return ({
      ...state,
      project,
      audioStates: (project.tracks || []).map(() => ({
        isPlaying: false,
        isLooping: false,
      })),
    })
  }
  if (action.type === LOAD_SOUNDBOX_PROJECTS_SUCCESS) {
    const {
      response: {
        projects,
      },
    } = action
    return ({
      ...state,
      projects,
    })
  }
  return state
}

export default soundboxReducer
