export const LOAD_SOUNDBOX_PROJECTS_REQUEST = 'soundboxProjects/load/request'
export const LOAD_SOUNDBOX_PROJECTS_SUCCESS = 'soundboxProjects/load/success'
export const LOAD_SOUNDBOX_PROJECTS_FAILURE = 'soundboxProjects/load/failure'
export const CREATE_SOUNDBOX_PROJECT_REQUEST = 'soundboxProjects/create/request'
export const CREATE_SOUNDBOX_PROJECT_SUCCESS = 'soundboxProjects/create/success'
export const CREATE_SOUNDBOX_PROJECT_FAILURE = 'soundboxProjects/create/failure'
export const LOAD_SOUNDBOX_PROJECT_REQUEST = 'soundboxProject/load/request'
export const LOAD_SOUNDBOX_PROJECT_SUCCESS = 'soundboxProject/load/success'
export const LOAD_SOUNDBOX_PROJECT_FAILURE = 'soundboxProject/load/failure'
export const SOUNDBOX_ADD_TRACK_REQUEST = 'soundboxProject/addTrack/request'
export const SOUNDBOX_ADD_TRACK_SUCCESS = 'soundboxProject/addTrack/success'
export const SOUNDBOX_ADD_TRACK_FAILURE = 'soundboxProject/addTrack/failure'
