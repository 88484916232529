import {
  combineReducers,
} from 'redux'
import {
  routerReducer,
} from 'react-router-redux'
import {
  reducer as formReducer,
} from 'redux-form'

import loadingReducer from 'reducers/loading'
import uiReducer from 'reducers/ui'
import sessionReducer from 'reducers/session'
import audioReducer from 'reducers/audio'
import soundboxReducer from 'reducers/soundbox'

export const reducers = {
  routing: routerReducer,
  session: sessionReducer,
  soundbox: soundboxReducer,
  loading: loadingReducer,
  audio: audioReducer,
  ui: uiReducer,
  form: formReducer,
}

export default combineReducers(reducers)
