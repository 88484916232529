import React from 'react'
import {
  connect,
} from 'react-redux'
import {
  Link as RouterLink,
} from 'react-router'
import {
  makeStyles,
} from '@material-ui/core/styles'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import HomeIcon from '@material-ui/icons/Home'
import SoundboxProjectListIcon from '@material-ui/icons/QueueMusic'
import SoundLibraryIcon from '@material-ui/icons/LibraryMusic'

import {
  getIsDrawerOpen,
} from 'selectors/ui'
import {
  closeDrawer as closeDrawerAction,
} from 'actions/ui'
import {
  SOUND_LIBRARY_PAGE,
  HOME_PAGE,
  SOUNDBOX_PROJECT_LIST_PAGE,
} from 'constants/navigation'
import {
  getCurrentPath,
} from 'selectors/routing'

export const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  navIcon: {
    marginLeft: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
}))

const AppDrawer = ({
  closeDrawer,
  isDrawerOpen,
  hasAuth,
  currentPath,
}) => {
  const classes = useStyles()
  return (
    <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
        }}
        open={isDrawerOpen}
      >
      <div className={classes.toolbarIcon}>
        <IconButton
            onClick={closeDrawer}
            aria-label="close drawer"
          >
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <li>
          <NavListItem
              to={`/${HOME_PAGE}`}
              rootPath={[HOME_PAGE]}
              title="Home"
              icon={<HomeIcon />}
              {...{
                classes,
                currentPath,
                isDrawerOpen,
              }}
          />
        </li>
        <li>
          <NavListItem
              to={`/${SOUNDBOX_PROJECT_LIST_PAGE}`}
              rootPath={[SOUNDBOX_PROJECT_LIST_PAGE]}
              title="Projects"
              icon={<SoundboxProjectListIcon />}
              {...{
                classes,
                currentPath,
                isDrawerOpen,
              }}
          />
        </li>
        <li>
          <NavListItem
              to={`/${SOUND_LIBRARY_PAGE}`}
              rootPath={[SOUND_LIBRARY_PAGE]}
              title="Library"
              icon={<SoundLibraryIcon />}
              {...{
                classes,
                currentPath,
                isDrawerOpen,
              }}
          />
        </li>
      </List>
    </Drawer>
  )
}

export const NavListItem = ({
  icon,
  rootPath,
  currentPath,
  classes,
  to,
  title,
  isDrawerOpen,
}) => {
  const isActivePath = currentPath.slice(0, rootPath.length).join('/') === rootPath.join('/')
  return (
    <ListItem
        button
        component={RouterLink}
        selected={isActivePath}
        {...{
          to,
        }}
      >
      {(icon && !isDrawerOpen) && (
        <Tooltip
            title={<Typography>{title}</Typography>}
            placement="right"
            arrow
          >
          <ListItemIcon className={classes.navIcon}>
            {icon}
          </ListItemIcon>
        </Tooltip>
      )}
      {(icon && isDrawerOpen) && (
        <ListItemIcon className={classes.navIcon}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText primary={title} />
    </ListItem>
  )
}

export const mapStateToProps = (
  state,
  ownProps,
) => ({
  isDrawerOpen: getIsDrawerOpen(state, ownProps),
  currentPath: getCurrentPath(state, ownProps),
})

export const mapDispatchToProps = {
  closeDrawer: closeDrawerAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppDrawer)
