import Auth from '@aws-amplify/auth'
import {
  Hub,
} from '@aws-amplify/core'

import cognitoConfig from 'config/cognito'

Auth.configure({
  Auth: cognitoConfig,
})

export const addListener = (callback) => {
  Hub.listen('auth', (data) => {
    callback(data.payload)
  })
}

export const loadAuthToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken()
}

export const signOut = () => Auth.signOut()

export const signIn = ({
  username,
  password,
}) => Auth.signIn(
  username,
  password,
)

export const signUp = ({
  username,
  password,
  email,
}) => Auth.signUp({
  username,
  password,
  attributes: {
    email,
  },
})

export const currentSession = () => Auth.currentSession()

export const currentUserInfo = () => Auth.currentUserInfo()

export const confirmSignUp = ({
  email,
  code,
}) => Auth.confirmSignUp(
  email,
  code,
)

export const forgotPassword = ({
  email,
}) => Auth.forgotPassword(
  email,
)

export const forgotPasswordSubmit = ({
  email,
  code,
  password,
}) => Auth.forgotPasswordSubmit(
  email,
  code,
  password,
)
