import React from 'react'
import {
  connect,
} from 'react-redux'
import Dialog from '@material-ui/core/Dialog'

import modalRegistry from 'modals'
import {
  closeModal as closeModalAction,
} from 'actions/ui'
import {
  getModalName,
} from 'selectors/ui'

export const ModalBase = ({
  modalName,
  onClose,
}) => {
  const ModalContent = modalRegistry[modalName]
  return (
    <Dialog
        open
        {...{
          onClose,
        }}
      >
      <ModalContent />
    </Dialog>
  )
}

export const mapStateToProps = (
  state,
  ownProps,
) => ({
  modalName: getModalName(state, ownProps),
})

export const mapDispatchToProps = {
  onClose: closeModalAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBase)
