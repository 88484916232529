import {
  put,
  delay,
  select,
  take,
  fork,
  takeEvery,
  call,
} from 'redux-saga/effects'
import {
  SubmissionError,
} from 'redux-form'
import {
  browserHistory,
} from 'react-router'

import {
  enqueueSnackbar,
} from 'actions/ui'
import {
  FORGOT_PASSWORD_SUBMIT_SUCCESS,
  FORGOT_PASSWORD_SUBMIT_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILURE,
  SIGN_IN_FAILURE,
  SIGN_UP_FAILURE,
  SIGN_UP_CONFIRM_SUCCESS,
  SIGN_UP_CONFIRM_FAILURE,
} from 'constants/session'
import {
  COGNITO_EVENT,
} from 'middlewares/cognito'
import {
  LANDING_PAGE,
  LOGIN_PAGE,
  SIGN_UP_CONFIRM_PAGE,
  FORGOT_PASSWORD_SUBMIT_PAGE,
} from 'constants/navigation'
import {
  getLocationBeforeTransitions,
} from 'selectors/routing'

export function * saveProfileFailureEffect ({
  error,
  form,
}) {
  yield call(form.reject, new SubmissionError({
    _error: error,
  }))
}

export function * saveProfileFailure () {
  yield takeEvery(
    SAVE_PROFILE_FAILURE,
    saveProfileFailureEffect,
  )
}

export function * signInFailureEffect ({
  error,
  form,
}) {
  yield call(form.reject, new SubmissionError({
    _error: error,
  }))
}

export function * signInFailure () {
  yield takeEvery(
    SIGN_IN_FAILURE,
    signInFailureEffect,
  )
}

export function * signUpFailureEffect ({
  error,
  form,
}) {
  yield call(form.reject, new SubmissionError({
    _error: error,
  }))
}

export function * signUpFailure () {
  yield takeEvery(
    SIGN_UP_FAILURE,
    signUpFailureEffect,
  )
}

export function * signUpConfirmFailureEffect ({
  error,
  form,
}) {
  yield call(form.reject, new SubmissionError({
    _error: error,
  }))
}

export function * signUpConfirmFailure () {
  yield takeEvery(
    SIGN_UP_CONFIRM_FAILURE,
    signUpConfirmFailureEffect,
  )
}

export function * saveProfileSuccessEffect () {
  yield call(
    browserHistory.push,
    `/${LANDING_PAGE}`,
  )
}

export function * saveProfileSuccess () {
  yield takeEvery(
    SAVE_PROFILE_SUCCESS,
    saveProfileSuccessEffect,
  )
}

export function * signUpConfirmSuccessEffect () {
  yield call(browserHistory.push, `/${LOGIN_PAGE}`)
}

export function * signUpConfirmSuccess () {
  yield takeEvery(
    SIGN_UP_CONFIRM_SUCCESS,
    signUpConfirmSuccessEffect,
  )
}

export function * signInSuccessEffect ({
  form,
}) {
  const locationBeforeTransitions = yield select(getLocationBeforeTransitions)
  if (locationBeforeTransitions && locationBeforeTransitions.state && locationBeforeTransitions.state.nextPathname) {
    yield call(browserHistory.push, locationBeforeTransitions.state.nextPathname)
  } else {
    yield call(browserHistory.push, `/${LANDING_PAGE}`)
  }
}

export function * signOutSuccessEffect () {
  yield call(browserHistory.push, `/${LOGIN_PAGE}`)
}

export function * signUpSuccessEffect () {
  yield call(browserHistory.push, `/${SIGN_UP_CONFIRM_PAGE}`)
}

export function * cognitoEvent () {
  while (true) {
    const action = yield take(COGNITO_EVENT)
    if (action.payload.event === 'signIn') {
      yield fork(
        signInSuccessEffect,
        action,
      )
    }
    if (action.payload.event === 'signOut') {
      yield fork(
        signOutSuccessEffect,
        action,
      )
    }
    if (action.payload.event === 'signUp') {
      yield fork(
        signUpSuccessEffect,
        action,
      )
    }
  }
}

export function * forgotPasswordFailureEffect ({
  error,
  form,
}) {
  yield call(form.reject, new SubmissionError({
    _error: error,
  }))
}

export function * forgotPasswordFailure () {
  yield takeEvery(
    FORGOT_PASSWORD_FAILURE,
    forgotPasswordFailureEffect,
  )
}

export function * forgotPasswordSuccessEffect (action) {
  yield call(action.form.resolve)
  yield call(
    browserHistory.push,
    `/${FORGOT_PASSWORD_SUBMIT_PAGE}`,
  )
  yield delay(0)
  yield put(enqueueSnackbar({
    message: 'Account recovery instructions have been sent to your email.',
    options: {
      key: `forgot-password-success-${Math.random().toString(16).slice(-6)}`,
      variant: 'info',
    },
  }))
}

export function * forgotPasswordSuccess () {
  yield takeEvery(
    FORGOT_PASSWORD_SUCCESS,
    forgotPasswordSuccessEffect,
  )
}

export function * forgotPasswordSubmitFailureEffect ({
  error,
  form,
}) {
  yield call(form.reject, new SubmissionError({
    _error: error,
  }))
}

export function * forgotPasswordSubmitFailure () {
  yield takeEvery(
    FORGOT_PASSWORD_SUBMIT_FAILURE,
    forgotPasswordSubmitFailureEffect,
  )
}

export function * forgotPasswordSubmitSuccessEffect (action) {
  yield call(action.form.resolve)
  yield call(
    browserHistory.push,
    `/${LOGIN_PAGE}`,
  )
  yield delay(0)
  yield put(enqueueSnackbar({
    message: 'Please log in with your new password',
    options: {
      key: `forgot-password-submit-success-${Math.random().toString(16).slice(-6)}`,
      variant: 'info',
    },
  }))
}

export function * forgotPasswordSubmitSuccess () {
  yield takeEvery(
    FORGOT_PASSWORD_SUBMIT_SUCCESS,
    forgotPasswordSubmitSuccessEffect,
  )
}
