;[
  'DOMAIN',
].forEach((environmentVariable) => {
  if (!process.env[environmentVariable]) {
    throw new Error(`config: environment variable '${environmentVariable}' is required`)
  }
})

export default ({
  domain: process.env.DOMAIN,
})
