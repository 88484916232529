import {
  CALL_AUDIO,
} from 'middlewares/audio'
import {
  PLAY_AUDIO_REQUEST,
  PLAY_AUDIO_SUCCESS,
  PLAY_AUDIO_FAILURE,
  STOP_AUDIO_REQUEST,
  STOP_AUDIO_SUCCESS,
  STOP_AUDIO_FAILURE,
  LOOP_AUDIO_REQUEST,
  LOOP_AUDIO_SUCCESS,
  LOOP_AUDIO_FAILURE,
  SET_AUDIO_VOLUME_REQUEST,
  SET_AUDIO_VOLUME_SUCCESS,
  SET_AUDIO_VOLUME_FAILURE,
  AUDIO_UNLOAD_REQUEST,
  AUDIO_UNLOAD_SUCCESS,
  AUDIO_UNLOAD_FAILURE,
} from 'constants/audio'

export const unloadAudio = (
  resolve,
  reject,
) => ({
  [CALL_AUDIO]: {
    method: 'unload',
    types: [
      AUDIO_UNLOAD_REQUEST,
      AUDIO_UNLOAD_SUCCESS,
      AUDIO_UNLOAD_FAILURE,
    ],
    data: {
    },
    form: {
      resolve,
      reject,
    },
  },
})

export const playAudio = (
  src,
  channel,
  resolve,
  reject,
) => ({
  [CALL_AUDIO]: {
    method: 'play',
    data: {
      src,
      channel,
    },
    types: [
      PLAY_AUDIO_REQUEST,
      PLAY_AUDIO_SUCCESS,
      PLAY_AUDIO_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const stopAudio = (
  channel,
  resolve,
  reject,
) => ({
  [CALL_AUDIO]: {
    method: 'stop',
    data: {
      channel,
    },
    types: [
      STOP_AUDIO_REQUEST,
      STOP_AUDIO_SUCCESS,
      STOP_AUDIO_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const loopAudio = (
  isActive,
  channel,
) => ({
  [CALL_AUDIO]: {
    method: 'loop',
    data: {
      channel,
      isActive,
    },
    types: [
      LOOP_AUDIO_REQUEST,
      LOOP_AUDIO_SUCCESS,
      LOOP_AUDIO_FAILURE,
    ],
  },
})

export const setAudioVolume = (
  value,
  channel,
) => ({
  [CALL_AUDIO]: {
    method: 'volume',
    data: {
      channel,
      value,
    },
    types: [
      SET_AUDIO_VOLUME_REQUEST,
      SET_AUDIO_VOLUME_SUCCESS,
      SET_AUDIO_VOLUME_FAILURE,
    ],
  },
})
