import {
  all,
} from 'redux-saga/effects'

import {
  cognitoEvent,
  saveProfileFailure,
  saveProfileSuccess,
  signInFailure,
  signUpConfirmFailure,
  signUpConfirmSuccess,
  signUpFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordSubmitSuccess,
  forgotPasswordSubmitFailure,
} from 'sagas/session'
import {
  uploadFileFailure,
  uploadFileToLibrary,
} from 'sagas/ui'
import {
  loadSoundboxProjectsFailure,
  createSoundboxProjectFailure,
  createSoundboxProjectSuccess,
  loadSoundboxProjectFailure,
  soundboxAddTrackFailure,
  soundboxAddTrackSuccess,
} from 'sagas/soundbox'

export default function * rootSaga () {
  yield all([
    ...[ // UI
      uploadFileFailure(),
      uploadFileToLibrary(),
    ],
    ...[ // SESSION
      cognitoEvent(),
      saveProfileFailure(),
      saveProfileSuccess(),
      signInFailure(),
      signUpConfirmFailure(),
      signUpConfirmSuccess(),
      signUpFailure(),
      forgotPasswordSuccess(),
      forgotPasswordFailure(),
      forgotPasswordSubmitSuccess(),
      forgotPasswordSubmitFailure(),
    ],
    ...[ // SOUNDBOX
      loadSoundboxProjectsFailure(),
      createSoundboxProjectFailure(),
      createSoundboxProjectSuccess(),
      loadSoundboxProjectFailure(),
      soundboxAddTrackFailure(),
      soundboxAddTrackSuccess(),
    ],
  ])
}
