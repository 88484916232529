import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {
  Provider,
} from 'react-redux'
import {
  browserHistory,
  Router,
} from 'react-router'
import {
  syncHistoryWithStore,
} from 'react-router-redux'

import Session from 'containers/Session'
import configureStore from 'store'
import createRoutes from 'routes'

const store = configureStore({
})
const history = syncHistoryWithStore(
  browserHistory,
  store,
)

ReactDOM.render(
  <Provider {...{ store }}>
    <Session>
      <Router {...{ history }}>
        {createRoutes(store)}
      </Router>
    </Session>
  </Provider>
  , document.getElementById('js-app'),
)
