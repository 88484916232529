import React, {
  lazy,
} from 'react'
import {
  Route,
  Redirect,
  IndexRedirect,
} from 'react-router'

import {
  SOUNDBOX_PROJECT_LIST_PAGE,
  SOUNDBOX_PROJECT_PAGE,
  SOUND_LIBRARY_PAGE,
  HOME_PAGE,
  LANDING_PAGE,
  FORGOT_PASSWORD_SUBMIT_PAGE,
  SIGN_UP_CONFIRM_PAGE,
  LOGIN_PAGE,
  SIGNUP_PAGE,
  FORGOT_PASSWORD_PAGE,
  PROFILE_PAGE,
  SANDBOX_PAGE,
} from 'constants/navigation'
import AppBase from 'containers/AppBase'
import {
  composeEnterHooksSeries,
  composeLeaveHooksSeries,
} from 'utils/routes'
import {
  ensureCurrentUserInfo,
  ensureLoggedIn,
  ensureProfileComplete,
} from 'couriers/session'
import {
  ensureListFiles,
  ensureStoreParams,
} from 'couriers/ui'
import {
  ensureUnloadAudio,
} from 'couriers/audio'
import {
  ensureSoundboxProjects,
  ensureSoundboxProject,
} from 'couriers/soundbox'

const createRoutes = (store) => {
  return (
    <Route
        path="/"
        component={AppBase}
        onEnter={composeEnterHooksSeries(
          ensureCurrentUserInfo(store),
        )}
      >
      <Route
          path={SOUNDBOX_PROJECT_LIST_PAGE}
          component={lazy(() => import('pages/SoundboxProjectList'))}
          onEnter={composeEnterHooksSeries(
            ensureLoggedIn(store),
            ensureProfileComplete(store),
            ensureSoundboxProjects(store),
          )}
      />
      <Route
          path={SOUND_LIBRARY_PAGE}
          component={lazy(() => import('pages/SoundLibrary'))}
          onEnter={composeEnterHooksSeries(
            ensureLoggedIn(store),
            ensureProfileComplete(store),
            ensureListFiles(store),
          )}
          onLeave={composeLeaveHooksSeries(
            ensureUnloadAudio(store),
          )}
      />
      <Route
          path={`${SOUNDBOX_PROJECT_PAGE}/:soundboxProjectId`}
          component={lazy(() => import('pages/SoundboxProject'))}
          onEnter={composeEnterHooksSeries(
            ensureStoreParams(store),
            ensureLoggedIn(store),
            ensureProfileComplete(store),
            ensureSoundboxProject(store),
            ensureListFiles(store),
          )}
          onLeave={composeLeaveHooksSeries(
            ensureUnloadAudio(store),
          )}
      />
      <Route
          path={SANDBOX_PAGE}
          component={lazy(() => import('pages/Sandbox'))}
          onEnter={composeEnterHooksSeries(
            ensureLoggedIn(store),
            ensureProfileComplete(store),
            ensureListFiles(store),
          )}
      />
      <Route
          path={HOME_PAGE}
          component={lazy(() => import('pages/Home'))}
          onEnter={composeEnterHooksSeries(
            ensureLoggedIn(store),
            ensureProfileComplete(store),
          )}
      />
      <Route
          path={LOGIN_PAGE}
          component={lazy(() => import('pages/Login'))}
      />
      <Route
          path={SIGN_UP_CONFIRM_PAGE}
          component={lazy(() => import('pages/SignUpConfirm'))}
      />
      <Route
          path={SIGNUP_PAGE}
          component={lazy(() => import('pages/SignUp'))}
      />
      <Route
          path={PROFILE_PAGE}
          component={lazy(() => import('pages/Profile'))}
          onEnter={composeEnterHooksSeries(
            ensureLoggedIn(store),
          )}
      />
      <Route
          path={FORGOT_PASSWORD_PAGE}
          component={lazy(() => import('pages/ForgotPassword'))}
      />
      <Route
          path={FORGOT_PASSWORD_SUBMIT_PAGE}
          component={lazy(() => import('pages/ForgotPasswordSubmit'))}
      />
      <Redirect
          from="*"
          to={LANDING_PAGE}
      />
      <IndexRedirect to={LANDING_PAGE} />
    </Route>
  )
}

export default createRoutes
