import {
  loadAuthToken,
} from 'actions/session'
import {
  loadSoundboxProjects,
  loadSoundboxProject,
} from 'actions/soundbox'
import {
  LOAD_SOUNDBOX_PROJECT_FAILURE,
} from 'constants/soundbox'
import {
  HOME_PAGE,
} from 'constants/navigation'
import {
  getProjectId,
} from 'selectors/soundbox'

export const ensureSoundboxProjects = store => async (nextState, replace, next) => {
  const {
    response: token,
  } = await store.dispatch(loadAuthToken())
  await store.dispatch(loadSoundboxProjects(token))
  next()
}

export const ensureSoundboxProject = store => async (nextState, replace, next) => {
  const {
    response: token,
  } = await store.dispatch(loadAuthToken())
  const soundboxProjectId = getProjectId(store.getState(), nextState)
  const response = await store.dispatch(loadSoundboxProject(
    token,
    soundboxProjectId,
  ))
  if (response.type === LOAD_SOUNDBOX_PROJECT_FAILURE) {
    replace(`/${HOME_PAGE}`)
  }
  next()
}
