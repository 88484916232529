import * as s3Service from 'services/s3'

export const CALL_S3 = Symbol('CALL_S3')

export const makeS3Call = async (
  method,
  data = {},
) => {
  try {
    const result = await s3Service[method](data)
    return result
  } catch (e) {
    console.log('Error caught in `middlewares/s3` -- ', e.message, { method, data })
    throw e
  }
}

export const actionWith = (
  action,
  dat,
  form,
  extra,
  data,
) => {
  const finalAction = {
    ...action,
    ...dat,
    form,
    extra,
    data,
  }
  delete action[CALL_S3]
  return finalAction
}

export default (store) => (next) => async (action) => {
  const callS3 = action[CALL_S3]
  if (typeof callS3 === 'undefined') {
    return next(action)
  }
  const {
    method,
    types,
    data,
    form,
    extra,
  } = callS3
  const [
    REQUEST,
    SUCCESS,
    FAILURE,
  ] = types
  next(actionWith(action, { type: REQUEST }, form, extra, data))
  return makeS3Call(
    method,
    data,
  ).then(
    (response) => next(actionWith(action, {
      response,
      type: SUCCESS,
    }, form, extra, data)),
    (e) => next(actionWith(action, {
      type: FAILURE,
      error: e.message || e.error,
    }, form, extra, data)),
  )
}
