import React from 'react'
import {
  connect,
} from 'react-redux'
import Button from '@material-ui/core/Button'

import {
  closeSnackbar as closeSnackbarAction,
} from 'actions/ui'

export const SnackDismissButton = ({
  closeSnackbar,
  itemKey,
}) => {
  return (
    <Button
        onClick={() => closeSnackbar(itemKey)}
        color="inherit"
        size="small"
      >
      Dismiss
    </Button>
  )
}

export const mapStateToDispatch = () => ({
})

export const mapDispatchToProps = {
  closeSnackbar: closeSnackbarAction,
}

export default connect(mapStateToDispatch, mapDispatchToProps)(SnackDismissButton)
