export default ({
  action: {
    activatedOpacity: 0.24,
    active: '#fff',
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(255, 255, 255, 0.12)',
    focusOpacity: 0.12,
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16
  },
  background: {
    default: '#121212',
    level1: '#212121',
    level2: '#333',
    paper: '#424242'
  },
  common: {
    black: '#000',
    white: '#fff'
  },
  contrastThreshold: 3,
  divider: 'rgba(255, 255, 255, 0.12)',
  error: {
    contrastText: '#fff',
    dark: '#d32f2f',
    light: '#e57373',
    main: '#f44336'
  },
  grey: {
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    50: '#fafafa',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161'
  },
  info: {
    contrastText: '#fff',
    dark: '#1976d2',
    light: '#64b5f6',
    main: '#2196f3'
  },
  primary: {
    contrastText: 'rgba(0, 0, 0, 0.87)',
    dark: 'rgb(100, 141, 174)',
    light: 'rgb(166, 212, 250)',
    main: '#90caf9'
  },
  secondary: {
    contrastText: 'rgba(0, 0, 0, 0.87)',
    dark: 'rgb(170, 100, 123)',
    light: 'rgb(246, 165, 192)',
    main: '#f48fb1'
  },
  success: {
    contrastText: 'rgba(0, 0, 0, 0.87)',
    dark: '#388e3c',
    light: '#81c784',
    main: '#4caf50'
  },
  text: {
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
    icon: 'rgba(255, 255, 255, 0.5)',
    primary: '#fff',
    secondary: 'rgba(255, 255, 255, 0.7)'
  },
  tonalOffset: 0.2,
  type: 'dark',
  warning: {
    contrastText: 'rgba(0, 0, 0, 0.87)',
    dark: '#f57c00',
    light: '#ffb74d',
    main: '#ff9800'
  }
})
