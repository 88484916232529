import React from 'react'
import {
  reduxForm,
  FormSection,
} from 'redux-form'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import NameField from 'fields/Name'
import {
  validateFields,
} from 'utils/form'
import {
  soundboxProjectCreate as soundboxProjectCreateValidations,
} from 'validations/soundbox'

export const SoundboxProjectCreate = ({
  handleSubmit,
  submitting,
  error,
}) => (
  <form onSubmit={handleSubmit}>
    {error && (
      <Typography color="error">{error}</Typography>
    )}
    <FormSection name="project">
      <Grid container>
        <Grid
            item
            xs={12}
          >
          <NameField />
        </Grid>
        <Grid
            item
            xs={12}
          >
          <Button
              variant="contained"
              type="submit"
              disabled={submitting}
              fullWidth
              color="primary"
            >
            Create
          </Button>
        </Grid>
      </Grid>
    </FormSection>
  </form>
)

export default reduxForm({
  form: 'soundbox-project-create',
  validate: validateFields({
    project: soundboxProjectCreateValidations,
  }),
})(SoundboxProjectCreate)
