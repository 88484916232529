import {
  CALL_FETCH,
} from 'middlewares/fetch'
import {
  LOAD_SOUNDBOX_PROJECTS_REQUEST,
  LOAD_SOUNDBOX_PROJECTS_SUCCESS,
  LOAD_SOUNDBOX_PROJECTS_FAILURE,
  CREATE_SOUNDBOX_PROJECT_REQUEST,
  CREATE_SOUNDBOX_PROJECT_SUCCESS,
  CREATE_SOUNDBOX_PROJECT_FAILURE,
  LOAD_SOUNDBOX_PROJECT_REQUEST,
  LOAD_SOUNDBOX_PROJECT_SUCCESS,
  LOAD_SOUNDBOX_PROJECT_FAILURE,
  SOUNDBOX_ADD_TRACK_REQUEST,
  SOUNDBOX_ADD_TRACK_SUCCESS,
  SOUNDBOX_ADD_TRACK_FAILURE,
} from 'constants/soundbox'

export const soundboxAddTrack = (
  token,
  projectId,
  uri,
  resolve,
  reject,
) => ({
  [CALL_FETCH]: {
    endpoint: `/api/soundbox/projects/${projectId}/tracks`,
    method: 'POST',
    headers: {
      Authorization: token,
    },
    data: {
      uri,
    },
    types: [
      SOUNDBOX_ADD_TRACK_REQUEST,
      SOUNDBOX_ADD_TRACK_SUCCESS,
      SOUNDBOX_ADD_TRACK_FAILURE,
    ],
    form: {
      resolve,
      reject,
    },
  },
})

export const loadSoundboxProjects = (
  token,
  resolve,
  reject,
) => ({
  [CALL_FETCH]: {
    endpoint: '/api/soundbox/projects',
    method: 'GET',
    types: [
      LOAD_SOUNDBOX_PROJECTS_REQUEST,
      LOAD_SOUNDBOX_PROJECTS_SUCCESS,
      LOAD_SOUNDBOX_PROJECTS_FAILURE,
    ],
    headers: {
      Authorization: token,
    },
    form: {
      resolve,
      reject,
    },
  },
})

export const loadSoundboxProject = (
  token,
  soundboxProjectId,
  resolve,
  reject,
) => ({
  [CALL_FETCH]: {
    endpoint: `/api/soundbox/projects/${soundboxProjectId}`,
    method: 'GET',
    types: [
      LOAD_SOUNDBOX_PROJECT_REQUEST,
      LOAD_SOUNDBOX_PROJECT_SUCCESS,
      LOAD_SOUNDBOX_PROJECT_FAILURE,
    ],
    headers: {
      Authorization: token,
    },
    form: {
      resolve,
      reject,
    },
  },
})

export const soundboxProjectCreate = (
  token,
  name,
  resolve,
  reject,
) => ({
  [CALL_FETCH]: {
    endpoint: '/api/soundbox/projects',
    method: 'POST',
    types: [
      CREATE_SOUNDBOX_PROJECT_REQUEST,
      CREATE_SOUNDBOX_PROJECT_SUCCESS,
      CREATE_SOUNDBOX_PROJECT_FAILURE,
    ],
    data: {
      name,
    },
    headers: {
      Authorization: token,
    },
    form: {
      resolve,
      reject,
    },
  },
})
