import {
  COGNITO_EVENT,
} from 'middlewares/cognito'
import {
  CURRENT_USER_INFO_SUCCESS,
  LOAD_PROFILE_SUCCESS,
} from 'constants/session'

export const getDefaultState = () => ({
  isLoggedIn: false,
  user: null,
  profile: null,
})

const sessionReducer = (state = getDefaultState(), action) => {
  if (action.type === LOAD_PROFILE_SUCCESS) {
    return ({
      ...state,
      profile: action.response.profile,
    })
  }
  if (action.type === COGNITO_EVENT) {
    if (action.payload.event === 'signIn') {
      return ({
        ...state,
        isLoggedIn: true,
        user: action.payload.data,
      })
    }
    if (action.payload.event === 'signOut') {
      return ({
        ...state,
        isLoggedIn: false,
        user: null,
      })
    }
  }
  if (action.type === CURRENT_USER_INFO_SUCCESS) {
    if (!action.response) {
      return ({
        isLoggedIn: false,
        user: null,
      })
    } else {
      return ({
        ...state,
        isLoggedIn: true,
        user: action.response,
      })
    }
  }
  return state
}

export default sessionReducer
