import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MUISelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const Select = ({
  input,
  meta,
  label,
  options,
}) => {
  return (
    <FormControl
        error={meta.touched && meta.invalid}
        margin="normal"
        fullWidth
      >
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <MUISelect
          value={input.value}
          onChange={input.onChange}
        >
        {options.map((item, key) => (
          <MenuItem
              value={item.value}
              {...{
                key,
              }}
            >
            {item.label}
          </MenuItem>
        ))}
      </MUISelect>
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}

Select.defaultProps = {
  label: null,
  placeholder: null,
}

export default Select
