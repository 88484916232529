import React from 'react'
import {
  connect,
} from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import SoundboxAddTrackForm from 'forms/SoundboxAddTrack'
import {
  closeModal as closeModalAction,
} from 'actions/ui'
import {
  soundboxAddTrack as soundboxAddTrackSubmission,
} from 'submissions/soundbox'
import {
  soundboxAddTrack as soundboxAddTrackAction,
} from 'actions/soundbox'
import {
  loadAuthToken as loadAuthTokenAction,
} from 'actions/session'
import {
  getProjectId,
} from 'selectors/soundbox'
import {
  getFiles,
} from 'selectors/ui'

export const SoundboxAddTrack = ({
  projectId,
  loadAuthToken,
  closeModal,
  soundboxAddTrack,
  files,
}) => {
  return (
    <React.Fragment>
      <DialogTitle>Add a track</DialogTitle>
      <DialogContent>
        <SoundboxAddTrackForm
            tracks={files}
            initialValues={{
            }}
            onSubmit={soundboxAddTrackSubmission(
              loadAuthToken,
              soundboxAddTrack,
              projectId,
            )}
        />
      </DialogContent>
      <DialogActions>
        <Button
            onClick={closeModal}
            color="primary"
          >
          Close modal
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export const mapStateToProps = (state, ownProps) => ({
  projectId: getProjectId(state, ownProps),
  files: getFiles(state, ownProps),
})

export const mapDispatchToProps = {
  closeModal: closeModalAction,
  soundboxAddTrack: soundboxAddTrackAction,
  loadAuthToken: loadAuthTokenAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(SoundboxAddTrack)
