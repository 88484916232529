import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'

const Text = ({
  type,
  input,
  meta,
  multiline,
  placeholder,
  rows,
  label,
  min,
  step,
  startAdornment,
  autoComplete,
}) => {
  return (
    <FormControl
        error={meta.touched && meta.invalid}
        margin="normal"
        fullWidth
      >
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <Input
          id={input.name}
          value={input.value}
          onChange={input.onChange}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          {...{
            startAdornment,
            multiline,
            placeholder,
            rows,
            type,
            min,
            step,
            autoComplete,
          }}
      />
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}

Text.defaultProps = {
  label: null,
  placeholder: null,
  multiline: false,
  type: 'text',
}

export default Text
