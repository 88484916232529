import Storage from '@aws-amplify/storage'

import cognitoConfig from 'config/cognito'
import config from 'config'
import s3Config from 'config/s3'

Storage.configure({
  Auth: cognitoConfig,
  Storage: {
    AWSS3: s3Config,
  },
})

export const uploadFile = async ({
  file,
  path = '',
  level = 'protected',
}) => {
  await Storage.put(`${path}${file.name}`, file, {
    contentType: file.type,
    level,
  })
}

export const list = async ({
  path = '',
  level = 'protected',
  identityId,
}) => {
  const keys = await Storage.list(path, {
    level,
    identityId,
  })
  const fileUris = []
  for (let i = 0; i < keys.length; i++) {
    const {
      key,
    } = keys[i]
    let uri = `https://${config.domain}/${level}/${key}`
    if (level !== 'public') {
      uri = await Storage.get(key, {
        level,
      })
    }
    fileUris.push({
      key,
      uri,
    })
  }
  return fileUris
}
