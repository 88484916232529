export const HOME_PAGE = 'home'
export const LOGIN_PAGE = 'login'
export const SIGNUP_PAGE = 'signup'
export const PROFILE_PAGE = 'profile'
export const SIGN_UP_CONFIRM_PAGE = 'sign-up-confirm'
export const FORGOT_PASSWORD_PAGE = 'forgot-password'
export const FORGOT_PASSWORD_SUBMIT_PAGE = 'forgot-password-submit'
export const SANDBOX_PAGE = 'sandbox'
export const CODE_PAGE = 'code'
export const SOUNDBOX_PROJECT_PAGE = 'soundbox'
export const SOUNDBOX_PROJECT_LIST_PAGE = 'soundbox'
export const SOUND_LIBRARY_PAGE = 'sound-library'

export const LANDING_PAGE = HOME_PAGE
