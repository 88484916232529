import * as audioService from 'services/audio'

export const CALL_AUDIO = Symbol('CALL_AUDIO')

export const makeAudioCall = async (
  method,
  data = {},
) => {
  try {
    const result = await audioService[method](data)
    return result
  } catch (e) {
    console.log('Error caught in `middlewares/audo` -- ', e.message, { method, data })
    throw e
  }
}

export const actionWith = (
  action,
  dat,
  form,
  extra,
  data,
) => {
  const finalAction = ({
    ...action,
    ...dat,
    form,
    extra,
    data,
  })
  delete action[CALL_AUDIO]
  return finalAction
}

export default (store) => {
  audioService.init(store.dispatch)
  return (next) => async (action) => {
    const callAudio = action[CALL_AUDIO]
    if (typeof callAudio === 'undefined') {
      return next(action)
    }
    const {
      method,
      types,
      data,
      form,
      extra,
    } = callAudio
    const [
      REQUEST,
      SUCCESS,
      FAILURE,
    ] = types
    next(actionWith(action, { type: REQUEST }, form, extra, data))
    return makeAudioCall(
      method,
      data,
    ).then(
      (response) => next(actionWith(action, {
        response,
        type: SUCCESS,
      }, form, extra, data)),
      (e) => next(actionWith(action, {
        type: FAILURE,
        error: e.message || e.error,
      }, form, extra, data)),
    )
  }
}
