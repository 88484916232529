export const soundboxProjectCreate = (
  loadAuthToken,
  soundboxProjectCreateAction,
) => ({
  project: {
    name,
  },
}) => new Promise((resolve, reject) => {
  loadAuthToken().then(({ response: token }) => {
    soundboxProjectCreateAction(
      token,
      name,
      resolve,
      reject,
    )
  })
})

export const soundboxAddTrack = (
  loadAuthToken,
  soundboxAddTrackAction,
  projectId,
) => ({
  track: {
    uri: trackUri,
  },
}) => new Promise((resolve, reject) => {
  loadAuthToken().then(({ response: token }) => {
    soundboxAddTrackAction(
      token,
      projectId,
      trackUri,
      resolve,
      reject,
    )
  })
})
