import {
  createSelector,
} from 'reselect'

export const getUiState = (
  state,
) => state.ui

export const getModalName = createSelector(
  [
    getUiState,
  ], (
    uiState,
  ) => uiState.modalName,
)

export const getModalOptions = createSelector(
  [
    getUiState,
  ], (
    uiState,
  ) => uiState.modalOptions,
)

export const getIsModalOpen = createSelector(
  [
    getUiState,
  ], (
    uiState,
  ) => uiState.modalOpen,
)

export const getIsDrawerOpen = createSelector(
  [
    getUiState,
  ], (
    uiState,
  ) => uiState.drawerOpen,
)

export const getNotifications = createSelector(
  [
    getUiState,
  ], (
    uiState,
  ) => uiState.notifications,
)

export const getParams = createSelector(
  [
    getUiState,
  ], (
    uiState,
  ) => uiState.params,
)

export const getTheme = createSelector(
  [
    getUiState,
  ], (
    uiState,
  ) => uiState.theme,
)

export const getFiles = createSelector(
  [
    getUiState,
  ], (
    uiState,
  ) => uiState.files,
)

export const getFileUris = createSelector(
  [
    getFiles,
  ], (
    files,
  ) => files.map(i => i.uri),
)
