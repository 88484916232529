import React from 'react'
import {
  connect,
} from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import clsx from 'clsx'
import {
  makeStyles,
} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import LightThemeIcon from '@material-ui/icons/BrightnessHigh'
import DarkThemeIcon from '@material-ui/icons/Brightness4'
import {
  Link as RouterLink,
} from 'react-router'
import Button from '@material-ui/core/Button'

import {
  THEME_LIGHT,
  THEME_DARK,
} from 'constants/ui'
import {
  drawerWidth,
} from 'containers/AppDrawer'
import {
  getIsDrawerOpen,
  getTheme,
} from 'selectors/ui'
import {
  openDrawer as openDrawerAction,
  saveTheme as saveThemeAction,
} from 'actions/ui'
import {
  LOGIN_PAGE,
  SIGNUP_PAGE,
} from 'constants/navigation'
import {
  getIsLoggedIn,
  getUsername,
} from 'selectors/session'
import {
  logout as logoutAction,
} from 'actions/session'

export const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create([
      'width',
      'margin',
    ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create([
      'width',
      'margin',
    ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingRight: 24,
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  headerItem: {
    marginRight: theme.spacing(3),
  },
}))

export const AppHeader = ({
  isDrawerOpen,
  openDrawer,
  theme,
  saveTheme,
  isLoggedIn,
  username,
  logout,
}) => {
  const classes = useStyles()
  return (
    <AppBar
        position="absolute"
        color="default"
        className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}
      >
      <Toolbar className={classes.toolbar}>
        <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
          >
          <MenuIcon />
        </IconButton>
        <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
          Open Owl
        </Typography>
        {!isLoggedIn && (
          <React.Fragment>
            <Button
                color="inherit"
                component={RouterLink}
                to={`/${LOGIN_PAGE}`}
                className={classes.headerItem}
              >
              Log in
            </Button>
            <Button
                variant="outlined"
                color="inherit"
                component={RouterLink}
                to={`/${SIGNUP_PAGE}`}
                className={classes.headerItem}
              >
              Sign up
            </Button>
          </React.Fragment>
        )}
        {isLoggedIn && (
          <Button
              color="inherit"
              onClick={() => logout()}
            >
            Hello, {username}
          </Button>
        )}
        <IconButton
            color="inherit"
            onClick={() => saveTheme(theme === THEME_DARK ? THEME_LIGHT : THEME_DARK)}
            aria-label="toggle theme"
          >
          {theme !== THEME_DARK && <DarkThemeIcon />}
          {theme === THEME_DARK && <LightThemeIcon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export const mapStateToProps = (
  state,
  ownProps,
) => ({
  isDrawerOpen: getIsDrawerOpen(state, ownProps),
  theme: getTheme(state, ownProps),
  isLoggedIn: getIsLoggedIn(state, ownProps),
  username: getUsername(state, ownProps),
})

export const mapDispatchToProps = ({
  openDrawer: openDrawerAction,
  saveTheme: saveThemeAction,
  logout: logoutAction,
})

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
